<template>
  <div class="GlobalTable">
    <div class="center_cont">
      <div class="cont_top">
        <p>批量退单</p>
        <el-button type="primary" size="small" @click="outexport"
          >导入数据表格</el-button
        >
        <el-button size="small" type="warning" @click="seaAction"
          >查看操作日志</el-button
        >
      </div>
      <div class="footbgf">
        <div class="cot_footer">
          <span>查询数据列表（{{ seachDataList.length }}）</span>
          <!-- <el-button type="success" size="small" icon="el-icon-refresh" round
            >刷新状态</el-button
          > -->
        </div>
        <div style="margin: 0 10px; padding-bottom: 20px">
          <GlobalTable
            ref="GlobalTable"
            :maxHeight="400"
            :isPagination="false"
            v-loading="loading"
            element-loading-text="退单中..."
            :columns="tableColumns"
            :data="seachDataList"
          >
            <el-table-column label="旧机背贴码" slot="backCode" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.barCode && row.barCode.value"
                  >{{ row.barCode.key }}：{{ row.barCode.value }}</span
                >
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column label="规格" slot="sku" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.phoneMemory">{{ row.phoneMemory }}</span>
                <span>{{
                  row.phoneStorage ? "+" + row.phoneStorage : "--"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="报价金额" slot="price" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.quotePrice }}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单状态" slot="state" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.state === '00'">待确认</span>
                <span v-else-if="row.state === '01'">已确认</span>
                <span v-else-if="row.state === '02'">待支付</span>
                <span v-else-if="row.state === '03'">已绑码</span>
                <span v-else-if="row.state === '04'">已收货</span>
                <span v-else-if="row.state === '05'">降价收货</span>
                <span v-else-if="row.state === '10'">已取消</span>
                <span v-else-if="row.state === '20'">已作废</span>
                <span v-else-if="row.state === '30'">已退回</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" slot="operation" align="center">
              <template slot-scope="{ row }">
                <el-button size="mini" type="danger" @click="editBtn(row)"
                  >移除
                </el-button>
              </template>
            </el-table-column>
          </GlobalTable>
          <div class="buttom_footer">
            <!-- <el-button size="small" plain @click="cancellation">取消</el-button> -->
            <el-button size="small" type="primary" @click="Confirmation"
              >确认退单</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 串号提示弹窗 -->
    <el-dialog
      title="提示"
      :visible.sync="repaetDelVisible"
      :close-on-click-modal="false"
      width="380px"
      @closed="repaetDelVisible = false"
    >
      <div class="del-title">
        <div class="sure_tip">
          导入成功，已为您自动删除
          <span style="color: #ff8695">{{ repeatData.length }}条</span
          >重复数据！
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="repaetDelVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 导入订单退单 -->
    <el-dialog
      class="add-dialog"
      title="导入订单退单"
      :visible.sync="importVisible"
      :close-on-click-modal="false"
      width="510px"
      @closed="exportinDialogClosed"
    >
      <div class="step">
        <div class="step-title">
          <span
            >说明：仅支持操作外部订单号退单，需确保外部订单号在待绑码、已绑码状态下。退单后回收商侧将自动退回机款。退单需填写退单原因。</span
          >
        </div>
        <div class="step-content">
          <el-button
            type="success"
            icon="el-icon-download"
            size="medium"
            @click="download"
            >下载数据模板</el-button
          >
        </div>
        <el-form
          ref="importForm"
          :model="importForm"
          :rules="importRules"
          label-width="80px"
        >
          <el-form-item label="表格上传" prop="fileName" class="fileName">
            <el-input
              v-model="importForm.fileName"
              @clear="fileClear"
              :disabled="true"
              size="medium"
              clearable
            >
            </el-input>
            <div style="float: right; position: relative">
              <el-button size="medium" type="warning" icon="el-icon-upload2"
                >上传表格</el-button
              >
              <input
                accept=".xls, .xlsx"
                ref="fileUpload"
                @change="onUploadList"
                type="file"
                style="
                  opacity: 0;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  cursor: pointer;
                "
              />
            </div>
            <div style="color: #ff687b">
              仅支持.xls及.xlsx格式,单次上传大小不超过5M
            </div>
          </el-form-item>
        </el-form>
        <div class="step2-content"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importVisible = false">取消</el-button>
        <el-button type="primary" @click.native="uploadFile">确定</el-button>
      </span>
    </el-dialog>
    <!-- 操作日志 -->
    <DialogTbale
      @onClose="onClose"
      :loading="storeLoading"
      :show="deleteVisible"
      title="退单操作日志"
      :columns="storesColumns"
      :data="dialogStoreList"
      :currentPage="storeParams.pageNum"
      :total="storeParams.total"
      :pageSize="10"
      :width="800"
      @handleCurrentChange="getStoreList"
    >
      <div>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="选择时间">
            <el-date-picker size="small" v-model="entryTime" type="daterange" start-placeholder="开始日期"
              end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']">>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="getStoreList()">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table-column label="操作退单订单" slot="staffCount" align="center">
        <template slot-scope="{ row }">
          <span
            @click="staffDialog(row)"
            style="color:#0981ff;text-decoration:underline;cursor: pointer;"
            v-if="row.num"
          >
            {{ row.num }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作人"
        slot="storeName"
        class="store-name"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.optName }}-{{ row.optAccount }}</span>
        </template>
      </el-table-column>
    </DialogTbale>
    <!-- 退单记录 -->
    <DialogTbale
      @onClose="chargebackClose"
      :loading="chargebackLoading"
      :show="chargebackVisible"
      title="退单订单"
      :columns="chargebackColumns"
      :data="chargebackList"
      :currentPage="chargebackParams.pageNum"
      :total="chargebackParams.total"
      :pageSize="10"
      :width="1000"
      @handleCurrentChange="getchargebackList"
    >
      <el-table-column
        label="订单状态"
        slot="state"
        class="store-name"
        align="center"
      >
        <template slot-scope="{ row }">
          <span v-if="row.state === '00'">待确认</span>
          <span v-else-if="row.state === '01'">已确认</span>
          <span v-else-if="row.state === '02'">待支付</span>
          <span v-else-if="row.state === '03'">已绑码</span>
          <span v-else-if="row.state === '04'">已收货</span>
          <span v-else-if="row.state === '05'">降价收货</span>
          <span v-else-if="row.state === '10'">已取消</span>
          <span v-else-if="row.state === '20'">已作废</span>
          <span v-else-if="row.state === '30'">已退回</span>
        </template>
      </el-table-column>
      <el-table-column
        label="执行状态"
        slot="executstate"
        class="store-name"
        align="center"
      >
        <template slot-scope="{ row }">
          <span v-if="row.execState !== '02'">{{
            row.execState === "00" ? "执行中" : "退单成功"
          }}</span>
          <span v-else style="color:rgb(255, 104, 123)"
            >退单失败({{ row.errorMsg }})</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="退单原因"
        slot="storeManager"
        class="store-name"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.refundReason || "--" }}</span>
        </template>
      </el-table-column>
    </DialogTbale>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";

export default {
  name: "Table",
  data() {
    return {
      batchNo: "",
      storeParams: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      dialogStoreList: [],
      deleteVisible: false,
      storeLoading: false,
      storesColumns: [
        { label: "批次号", prop: "batchNo" },
        { slotName: "staffCount" },
        { slotName: "storeName" },
        { slotName: "storeAddress" },
        { label: "操作时间", prop: "createTime" },
      ],
      chargebackParams: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      chargebackList: [],
      chargebackVisible: false,
      chargebackLoading: false,
      chargebackColumns: [
        { label: "订单号", prop: "orderNo" },
        { slotName: "state" },
        { label: "外部订单号", prop: "orderNoOneline" },
        { label: "机型", prop: "phoneName" },
        { label: "成交回收商", prop: "merchantName" },
        { label: "门店商户", prop: "companyName" },
        { slotName: "executstate" },
        { slotName: "storeManager" },
      ],
      repaetDelVisible: false,
      repeatData: [],
      paramsExcel: {},
      importForm: {
        fileName: "",
      },
      importRules: {
        fileName: [
          {
            required: true,
            message: "仅支持.xls及.xlsx格式,单次上传大小不超过5M",
            trigger: "blur",
          },
        ],
      },
      importVisible: false,
      warehouseId: "",
      supplierId: "",
      loading: false,
      seachDataList: [],
      butLoading: false,
      tableColumns: [
        { label: "外部订单号", prop: "orderNoOneline" },
        { label: "订单号", prop: "orderNo" },
        { label: "旧机串号", prop: "imei" },
        { slotName: "backCode" },
        { label: "成交时间", prop: "ackTime" },
        { label: "机型", prop: "phoneName" },
        { slotName: "sku" },
        { slotName: "price" },
        { label: "门店商家", prop: "companyName" },
        { label: "成交回收商", prop: "merchantName" },
        { slotName: "state" },
        { slotName: "operation" },
      ],
      headers: {
        ACCESS_TOKEN: localStorage.getItem("token"),
        channel: "platformPc",
        TOKEN: "",
      },
      commandDisableShow: false,
      entryTime: null,
    };
  },
  created() {},
  watch: {
    importForm(newVal) {
      console.log(newVal, "importForm");
      if (!this.importForm.fileName) {
        this.paramsExcel.file = "";
      }
    },
  },
  methods: {
    getStoreList(val) {
      this.storeLoading = true;
      const params = {
        pageNum: val || 1,
        pageSize: this.storeParams.pageSize,
      };
      if (this.entryTime) {
        params.startTime = moment(this.entryTime[0]).format("x"),
        params.endTime = moment(this.entryTime[1]).format("x");
      }
      _api.batchList(params).then((res) => {
        if (res.code === 1) {
          this.dialogStoreList = res.data.records;
          this.storeParams.total = res.data.total;
          this.storeParams.pageNum = res.data.current;
          this.storeLoading = false;
        }
      });
    },
    onClose() {
      this.deleteVisible = false;
    },
    getchargebackList(val) {
      this.chargebackLoading = true;
      const params = {
        batchNo: this.batchNo,
        pageNum: val || 1,
        pageSize: this.chargebackParams.pageSize,
      };
      _api.batchDetailList(params).then((res) => {
        if (res.code === 1) {
          this.chargebackList = res.data.records;
          this.chargebackParams.total = res.data.total;
          this.chargebackParams.pageNum = res.data.current;
          this.chargebackLoading = false;
        }
      });
    },
    chargebackClose() {
      this.chargebackVisible = false;
    },
    //查看操作退单订单
    staffDialog(row) {
      this.batchNo = row.batchNo;
      this.getchargebackList(1);
      this.chargebackVisible = true;
    },
    //取消
    cancellation() {
      this.loading = false;
    },
    //确认退单
    Confirmation() {
      this.loading = true;
      _api
        .saveBatch({ orderInfoList: [...this.seachDataList] })
        .then((res) => {
          if (res.code === 1) {
            this.$message({
              type: "success",
              message: "退单结束，点击操作日志查看退单情况！",
            });
            this.seachDataList = [];
          } else {
            this.$message.error(res.msg || "退单失败！");
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          // this.$message.error(err.msg || "退单失败！");
        });
    },
    //表格清理
    fileClear() {
      this.$refs.fileUpload.value = "";
      this.paramsExcel = {};
    },
    //导入数据表格
    outexport() {
      this.importVisible = true;
    },
    onUploadList(event, type) {
      console.log(event);
      this.paramsExcel.file = event.target.files;
      if (this.paramsExcel.file.length) {
        this.paramsExcel.excelName = event.target.files[0].name;
        this.importForm.fileName = event.target.files[0].name;
      }
    },
    // 确定导出
    uploadFile() {
      if (!this.paramsExcel.file || this.paramsExcel.file.length == 0) {
        return this.$message.error("请上传表格");
      }
      const fd = new FormData();
      console.log(this.paramsExcel);
      fd.append("file", this.paramsExcel.file[0]); //文件
      console.log(fd);
      this.repeatData = [];
      // 发起请求
      _api
        .readOutOrderNo(fd)
        .then((res) => {
          console.log("_RequestUploads_", res);
          if (res.code === 1) {
            this.importForm.fileName = "";
            this.$refs.fileUpload.value = "";
            if (res.data.length > 0) {
              //如果退单有数据，删除重复数据
              if (this.seachDataList.length > 0) {
                this.seachDataList.unshift(...res.data);
                //去重后加入退单中
                const uniqueArr = this.seachDataList.filter(
                  (item, index) =>
                    this.seachDataList.findIndex(
                      (i) => i.orderNoOneline === item.orderNoOneline
                    ) === index
                );
                this.seachDataList = uniqueArr;
              } else {
                this.seachDataList.unshift(...res.data);
              }
              this.$message({
                type: "success",
                message: "导入成功！",
              });
              //给定唯一变量
              this.seachDataList.forEach((item, inds) => {
                item.index = inds;
              });
            } else {
              this.$message({
                type: "warning",
                message: "请导入正确的外部商户订单！",
              });
            }
            this.importVisible = false;
          } else {
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.importForm.fileName = "";
          this.$refs.fileUpload.value = "";
        });
    },
    // 弹窗关闭
    exportinDialogClosed() {
      if (this.$refs["addForm"]) {
        this.$refs["addForm"].resetFields();
      } else {
        this.$refs["importForm"].resetFields();
      }
      this.importForm = {
        fileName: "",
      };
      this.$refs.fileUpload.value = "";
      this.paramsExcel = {};
    },
    //下载模板
    download() {
      const link = document.createElement("a");
      link.href = "/static/导入退单数据模板.xlsx";
      link.setAttribute("download", "导入退单数据模板.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    //查看操作日志
    seaAction() {
      let startTime = moment()
      .day(moment().day())
      .format("YYYY-MM-DD 00:00:00"); // 当前时间往前推7天的时间
      let endTime = moment().format("YYYY-MM-DD 23:59:59");
      this.entryTime = [startTime, endTime];
      this.getStoreList(1);
      this.deleteVisible = true;
    },
    //新建出库
    addMatchm() {
      this.commandDisableShow = true;
    },
    // 确认关闭弹框
    submit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      this.butLoading = true;
      _api.closeMatchTransaction().then((res) => {
        this.$message.success(res.msg || "操作成功");
        this.butLoading = false;
      });
    },
    //删除
    editBtn(row) {
      this.seachDataList.splice(row.index, 1);
      this.seachDataList.forEach((item, inds) => {
        item.index = inds;
      });
      this.$forceUpdate;
      console.log(this.seachDataList);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.chuanhaozhan {
  background-color: #f5f6fa;
  border-radius: 20px;
  margin: 20px 0 15px 0;
  padding: 10px;
}

.step {
  .step-title {
    display: flex;
    color: #ff687b;
    margin-bottom: 18px;
  }

  .fileName {
    /deep/.el-input {
      width: 260px;
    }

    /deep/.el-button {
      // border-radius: 10px;
      margin-right: 0px;
    }
  }

  .step-content {
    margin-bottom: 20px;
  }
}

.cance-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;
}

/deep/.el-checkbox__inner {
  border-radius: 50% !important;
}

/deep/.el-button {
  // border-radius: 10px;
  margin-right: 30px;
}

//去掉加减箭头
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.topaction {
  padding: 0;
  // background: #E4ECFD;
  border-radius: 25px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.chuanhao {
  height: 100px;
  width: 100%;
  overflow: auto;
}

.footbgf {
  background: #fff;
  border-radius: 4px;
  padding-top: 1px;
}

.center_cont {
  // background: #fff;
  border-radius: 4px;

  .cot_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 14px 0 20px 20px;

    span:nth-of-type(1) {
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      color: #0981ff;
    }
  }

  .cont_top {
    padding-left: 20px;
    width: 100%;
    height: 90px;
    padding-top: 1px;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 16px;
    p {
      margin: 10px 0;
      font-size: 16px;
      color: #0981ff;
    }
  }
  .buttom_footer {
    padding: 20px 0 0 10px;
  }

  .ct_tpd {
    display: flex;
    align-items: center;
    margin: 20px 0 20px 15px;

    .cfhjs {
      font-size: 14px;
      font-family: FZLanTingHei-DB-GBK;
      font-weight: 400;
      color: #4c84ff;
      margin-left: 60px;

      span:nth-of-type(1) {
        font-weight: bold;
      }
    }

    .t_keft {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: FZLanTingHei-DB-GBK;
      font-weight: 400;
      color: #333333;

      img {
        width: 30px;
        height: 30px;
        margin-right: 8px;
      }
    }
  }

  .ct_conten {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 15px;
    margin-bottom: 20px;
    width: 95%;
    height: 60px;
    background: #f5f6fa;
    border-radius: 4px;

    .cf_jhj {
      display: flex;
      align-items: center;
      margin-right: 50px;
      // margin-bottom: 20px;

      span {
        font-size: 14px;
        color: #666666;
        padding-right: 8px;
      }
    }
  }
}

.GlobalTable {
  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .operation-btn {
    /deep/.el-button {
      margin-left: 0;
    }
  }

  .tableBtn {
    color: #0981ff;
    cursor: pointer;
  }

  .enable-tip {
    color: #ff8080;
    font-size: 14px;
    margin-bottom: 20px;

    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .add-form {
    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }

    /deep/.el-input-group__prepend {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }

    /deep/.el-select {
      width: 100%;
    }
  }

  .scancode {
    color: #ff8080;
    font-size: 18px;
    text-align: center;
  }

  .aline {
    display: flex;
    justify-content: center;

    /deep/.el-button {
      width: 200px;
      border-radius: 15px;
    }
  }

  .rules-form {
    .rule-title {
      display: flex;
      align-items: center;
      color: #ff8080;
      margin-bottom: 20px;
    }

    .tip_ico {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }

    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
  }

  .del-title {
    color: #ff8080;
    font-size: 12px;
    text-align: center;

    .sure_tip {
      margin-top: 15px;
      margin-bottom: 20px;
      font-size: 16px;
      color: #333333;
    }
  }

  .command2 {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    /deep/.el-input {
      width: 300px;
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex1 {
    flex: 1;
  }

  .overflow-hiding {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-break: break-all;
    -webkit-line-clamp: 1;
    /*! autoprefixer: off */
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    /*! autoprefixer: on */
  }
}
</style>
