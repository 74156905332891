<template>
  <div class="OrderList">
    <GlobalInfoBar
      title="导入订单退单"
      content="仅支持导入外部商户订单，且繁星订单状态为已成交未收货，线上支付、未付款给顾客"
    />
    <div class="caseOrder">
      <OrderTable />
    </div>
  </div>
</template>

<script>
import _api from "@/utils/request";
import OrderTable from "./table";
export default {
  name: "OrderList",
  components: { OrderTable },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.OrderList {
  .caseOrder {
    margin: 0 5px;
    background: #f5f6fa;
    border-radius: 10px;
  }
}
</style>
